import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import { Typography, Box, makeStyles, Button, Theme } from '@material-ui/core';
import PopularPostsCard from 'components/Cards/Widgets/PopularPosts';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import { generateLogger } from 'utils/loggers';
import { ImageSharp } from 'models/image';
import Emoji from 'components/Utils/Emoji';

const logger = generateLogger('error_page');
const handlePostClick = (path: string) => {
  logger.click('click_posting', { path });
};
const handleMainButtonClick = () => {
  logger.click('click_home_button');
};

const useStyles = makeStyles<Theme>(({ spacing, breakpoints }) => ({
  image: {
    width: '40%',
    marginBottom: spacing(3),
    [breakpoints.up('sm')]: {
      width: 500,
    },
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  postCardWrapper: {
    marginTop: spacing(8),
  },
  postCard: {
    maxWidth: '500px',
    margin: '0 auto',
  },
  goBackButtonWrapper: {
    marginTop: spacing(8),
  },
  link: {
    textDecoration: 'none',
  },
}));

const ErrorPage = () => {
  const styles = useStyles();
  const { errorImage } = useStaticQuery<{ errorImage: ImageSharp }>(graphql`
    query {
      errorImage: file(absolutePath: { regex: "/404.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  useEffect(() => {
    logger.view();
  }, []);

  return (
    <Layout>
      <SEO title="404: Not Found" />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Image className={styles.image} fluid={errorImage.childImageSharp.fluid} />
        <Typography className={styles.textAlignCenter} variant="h4" component="h2">
          길을 잃어버리셨네요...
          <Emoji emoji="😭" name="404" />
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" flexDirection="column" className={styles.postCardWrapper}>
        <Typography className={styles.textAlignCenter} gutterBottom>
          혹시 이런 포스팅은 어떠신가요?
        </Typography>
        <Box className={styles.postCard}>
          <PopularPostsCard onClick={handlePostClick} />
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" className={styles.goBackButtonWrapper}>
        <Link to="/" className={styles.link}>
          <Button size="large" variant="contained" color="primary" onClick={handleMainButtonClick}>
            다른 포스팅 보러가기
          </Button>
        </Link>
      </Box>
    </Layout>
  );
};

export default ErrorPage;
